<template>
<div>
    <el-row class="dataRow" v-if='isFilter'>
        <el-col :span="15" class="margin-tb" >
            <span>拆解维度：</span>  
            <el-checkbox v-model="witchChecked.one" disabled>日期</el-checkbox>
            <el-checkbox v-model="witchChecked.two" @change="handleChangeDeep">媒体</el-checkbox>
            <el-checkbox v-model="witchChecked.three" @change="handleChangeDeep">广告位</el-checkbox>
            <el-checkbox v-model="witchChecked.four" @change="handleChangeDeep">国家/地区</el-checkbox>
        </el-col>
        <el-col :span="1" :offset="8" class="downLoadBtn">
            <el-button class="btn" size="small" @click="exportExcel">下载</el-button>
        </el-col>
    </el-row>
    <el-table
        :data="tableData"
        id="out__table"
        border
        stripe
        :max-height="480"
        style="width: 100%"
        >
        <el-table-column
        prop="moneyDate"
        label="日期"
        v-if="witchChecked.one"
        >
        </el-table-column>
        <el-table-column
        prop="media_name"
        label="媒体"
        v-if="witchChecked.two"
        >
        </el-table-column>
        <el-table-column
        prop="advplace_name"
        v-if="witchChecked.three"
        label="广告位"
        width="180"
        >
        </el-table-column>
        <el-table-column
        prop="country"
        v-if="witchChecked.four"
        label="国家/地区"
        >
        </el-table-column>
        <el-table-column
        prop="request_total"
        label="广告请求"
        >
        </el-table-column>
        <el-table-column
        prop="request_back"
        label="匹配请求"
        >
        </el-table-column>
        <el-table-column
        prop="request_rate"
        label="覆盖率"
        >
        </el-table-column>
        <el-table-column
        prop="show_total"
        label="广告展示次数"
        >
        </el-table-column>
        <el-table-column
        prop="click_total"
        label="点击次数"
        >
        </el-table-column>
        <el-table-column
        prop="money_total"
        label="预计收入（US$）"
        >
        </el-table-column>
        <el-table-column
        prop="click_rate"
        label="点击率"
        >
        </el-table-column>
        <el-table-column
        prop="cpm"
        label="eCPM（US$）"
        >
        </el-table-column>
        <el-table-column
        prop="activeView"
        label="可见率"
        >
        </el-table-column>
    </el-table>
    <Paging
      :dataList='tableData' 
      @changeSize='changeSize'
      @changePage='changePage'
      :total='total'
      :returnPage1='returnPage1'
    />
</div>
</template>

<script>
  import Paging from '../components/Paging.vue'
  import FileSaver from 'file-saver'
  import XLSX from 'xlsx'
  export default {
    props:{
      isFilter:{
        type:Boolean,
        default:true
      },
      tableData:{
        type:Array,
      },
      total:{
        type:Number,
        default:10
      },
      returnPage1:{
        type:Number,
      }
    },
    components:{
      Paging
    },
    data() {
      return {
        witchChecked:{
          one:true,
          two:false,
          three:false,
          four:false
        },
      }
    },
    methods: {
      handleChangeDeep(){
        let {witchChecked} = this
        let arr = []
        witchChecked.two && arr.push('medium')
        witchChecked.three && arr.push('advplace')
        witchChecked.four && arr.push('country')
        this.$emit('passGroupBy',arr)
        sessionStorage.setItem('groupBy',JSON.stringify(arr))
      },
      changeSize(val){
        this.$emit('changeSize',val)
      },
      changePage(val){
        this.$emit('changePage',val)
      },
      exportExcel() {
        var wb = XLSX.utils.table_to_book(document.querySelector('#out__table'))
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
            FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sheetjs.xls')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },
    },
    created(){
      let checkedGroup = sessionStorage.getItem('groupBy')
      if(checkedGroup){
        let {witchChecked} = this
        JSON.parse(checkedGroup).forEach(item=>{
          if(item==='medium'){witchChecked.two = true}
          if(item==='advplace'){witchChecked.three = true}
          if(item==='country'){witchChecked.four = true}
        })
      }
    }
  }
</script>
<style lang="less" scoped>
    .dataRow{
      .margin-tb{
        font-size: 14px;
        span{
          color: #333;
          font-weight: 900;
        }
      }
    }
    .downLoadBtn{
      .el-button--small{
        color: #989899 !important;
      }
    }
    .alignRight{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
</style>